import React from "react";
import { service } from "../Common/Data";
import "./HomeSliderStyle.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actions from "./../Redux/actions/actions.js";

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: 0,
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % service.length,
    };
  }
  if (event.type === "PREV") {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? service.length - 1 : state.slideIndex - 1,
    };
  }
};

function Slide({ slide, offset }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  const handleClickSlide = (e, slide) => {
    dispatch(actions.getProductDetails(slide));
    navigate("/product/details");
  };

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1,
        display: offset === -2 || offset === 2 ? "none" : "block",
      }}
      onClick={(e) => handleClickSlide(e, slide)}
    >
      <div
        className="slideBackground"
        // style={{
        //   backgroundImage: `url('${slide.img1}')`,
        // }}
      />
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${slide.img1}')`,
        }}
      >
        <div className="slideContentInner">
          <h3 className="slideSubtitle">{slide.name}</h3>
        </div>
      </div>
    </div>
  );
}

function AppSlider() {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);

  return (
    <div className="slides">
      <button onClick={() => dispatch({ type: "PREV" })}>
        <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>
      </button>

      {[...service, ...service, ...service].map((slide, i) => {
        let offset = service.length + (state.slideIndex - i);
        return <Slide slide={slide} offset={offset} key={i} />;
      })}
      <button onClick={() => dispatch({ type: "NEXT" })}>
        <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
      </button>
    </div>
  );
}
export default AppSlider;
