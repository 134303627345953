import React, { useState } from "react";
import { service } from "../Common/Data.js";
import ServiceDetails from "./ServiceDetails";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "./../Redux/actions/actions.js";

const Service = () => {
  const [isDeatils, setIsDetails] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGetDetails = (e, item) => {
    e.preventDefault();
    setIsDetails(true);
    setProductDetails(item);
    dispatch(actions.getProductDetails(item));
    navigate("/product/details");
  };


  const handleShowMore = (e, item) => {
    e.stopPropagation();
    setShowMore(!showMore);
  };

  return (
    <>
      <section className="container">
        <div className="blogs-content">
          <div className="main-title">
            <h2>
              My <span>Products</span>
              <span className="bg-text">My Products</span>
            </h2>
          </div>
          <div className="row gy-5 blogs">
            {service?.map((item, index) => {
              return (
                <div
                  className="col-sm-12 col-md-3 col-lg-3 blog control"
                  key={item.id}
                  onClick={(e) => handleGetDetails(e, item)}
                >
                  <img
                    src={item.img1}
                    alt={item.name}
                    className="product-image"
                  />
                  <div className="blog-text">
                    <h4>{item.name}</h4>
                    <p>
                      {showMore
                        ? item?.description
                        : item?.description.substring(0, 150)}
                      <button
                        className="btn-showmore"
                        onClick={(e) => handleShowMore(e, item)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </button>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
