import React from "react";
import Logo from "./../assets/nte_new_logo.png";
import { contact } from "../Common/Data";

const Header = () => {
  return (
    <>
      {/* <div className="row header-style ">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <img src={Logo} alt="new tech" className="logo-image" />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 header-email">
              <i className="fa fa-address-bookaddress-book"></i>&nbsp;&nbsp;
              <h6>{contact.email}</h6>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 header-phone">
              <i className="fa fa-phone"></i>&nbsp;&nbsp;
              <h6>{contact.mobile}</h6>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row header-style">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 header-email">
            <i className="fa fa-envelope"></i>&nbsp;&nbsp;
            <h6>{contact.email}</h6>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 header-phone">
            <i className="fa fa-phone"></i>&nbsp;&nbsp;
            <h6>{contact.mobile}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
