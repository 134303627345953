import React, { useState } from "react";
import ModalComponent from "./../Components/ModalCom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ServiceDetails = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [clickedImg, setClickedImg] = useState({});
  const navigate = useNavigate();
  const productDetails = useSelector(
    (state) => state.ProductReducers && state.ProductReducers.productDetail
  );

  const {
    name,
    description,
    img1,
    img2,
    img3,
    feature,
    applications,
    usages,
    color,
    material,
    country_of_origin,
    Maximum_Temperature,
    Density,
    Thickness,
    Packaging_type,
    Note,
  } = productDetails;

  const handleOpenImage = (e, img) => {
    e.preventDefault();
    setIsShow(true);
    setClickedImg(img);
  };

  const closeModal = () => {
    setIsShow(false);
  };

  const handleBack = () => {
    navigate("/product");
  };
  const handleHomeButton = () => {
    navigate("/");
  };

  return (
    <>
      {isShow && (
        <ModalComponent
          isShow={isShow}
          closeModal={closeModal}
          name={name}
          clickedImg={clickedImg}
        />
      )}
      <div className="back_button">
        <button className="" onClick={handleBack}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
          &nbsp; Back
        </button>
        <button className="" onClick={handleHomeButton}>
          <i className="fa fa-home" aria-hidden="true"></i>
          &nbsp; Home
        </button>
      </div>
      <div className="blogs-content">
        <div className="product-contenet">
          <div className="main-title">
            <h2>
              My <span>Products Details</span>
              <span className="bg-text">My Products Details</span>
            </h2>
          </div>
        </div>

        <div className="products">
          <div className="product_details">
            <h4>{name}</h4>
          </div>

          <div className="row gy-3 product_img product_background">
            <div
              className="col-sm-12 col-md-4 col-lg-4"
              onClick={(e) => handleOpenImage(e, img1)}
            >
              <img src={img1} alt={name} className="image" />
            </div>
            <div
              className="col-sm-12 col-md-4 col-lg-4"
              onClick={(e) => handleOpenImage(e, img2)}
            >
              <img src={img2} alt={name} className="image" />
            </div>
            <div
              className="col-sm-12 col-md-4 col-lg-4"
              onClick={(e) => handleOpenImage(e, img3)}
            >
              <img src={img3} alt={name} className="image" />
            </div>
          </div>
        </div>
        <div className="description-area">
          <p>{description}</p>
        </div>
        {feature.length > 0 && (
          <div className="feature_area">
            <h4>
              <u>Features :</u>
            </h4>
            <ol>
              {feature &&
                feature?.map((feture, index) => {
                  return <li key={feture.id}>{feture.value}</li>;
                })}
            </ol>
          </div>
        )}
        {applications.length > 0 && (
          <div className="applications_area">
            <h4>
              <u>Applications :</u>
            </h4>
            <ol>
              {applications &&
                applications?.map((application, index) => {
                  return <li key={application.id}>{application.value}</li>;
                })}
            </ol>
          </div>
        )}
        {Maximum_Temperature !== "" && (
          <div className="det_area">
            <h4>
              <u>Maximum Temperature :</u>
            </h4>
            &nbsp;&nbsp;
            <p>{Maximum_Temperature}</p>
          </div>
        )}

        {Density !== "" && (
          <div className="det_area">
            <h4>
              <u>Density :</u>
            </h4>
            &nbsp;&nbsp;
            <p>{Density}</p>
          </div>
        )}
        {Thickness !== "" && (
          <div className="det_area">
            <h4>
              <u>Thickness :</u>
            </h4>
            &nbsp; &nbsp;
            <p>{Thickness}</p>
          </div>
        )}
        {Packaging_type !== "" && (
          <div className="det_area">
            <h4>
              <u>Packaging Type :</u>
            </h4>
            &nbsp;&nbsp;
            <p>{Packaging_type}</p>
          </div>
        )}
        {usages !== "" && (
          <div className="det_area">
            <h4>
              <u>Usage/Application:</u>
            </h4>
            &nbsp;&nbsp;
            <p>{usages}</p>
          </div>
        )}
        {color !== "" && (
          <div className="det_area">
            <h4>
              <u>Color:</u>
            </h4>
            &nbsp;&nbsp;
            <p>{color}</p>
          </div>
        )}
        {material !== "" && (
          <div className="det_area">
            <h4>
              <u>Material:</u>
            </h4>
            &nbsp;&nbsp;
            <p>{material}</p>
          </div>
        )}
        {country_of_origin !== "" && (
          <div className="det_area">
            <h4>
              <u>Country of Origin:</u>
            </h4>
            &nbsp;&nbsp;
            <p>{country_of_origin}</p>
          </div>
        )}
        <div className="note_area">
          <h4>Note :</h4>
          <p>{Note}</p>
        </div>
        <div className="buttom_area"></div>
      </div>
    </>
  );
};

export default ServiceDetails;
