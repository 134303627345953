import React from "react";
import { Link, useLocation } from "react-router-dom";
import { contact } from "../Common/Data";
import Logo from "./../assets/new-logo.png";
import Header from "./Header";

const Menubar = () => {
  (function () {
    [...document.querySelectorAll(".control")].forEach((button) => {
      button.addEventListener("click", function () {
        document.querySelector(".active-btn").classList.remove("active-btn");
        this.classList.add("active-btn");
        document.querySelector(".active").classList.remove("active");
        document.getElementById(button.dataset.id).classList.add("active");
      });
    });
  })();

  const handleClickTheme = () => {
    document.body.classList.toggle("light-mode");
    handleCollapse();
  };

  const location = useLocation();

  const handleCollapse = () => {
    var nav = document.getElementById("navbarNav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="new tech" className="logo-image" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            id="navbarBtn"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item" onClick={handleCollapse}>
                <Link
                  className={`nav-link ${
                    location.pathname === "/" ? "active active-style" : ""
                  }`}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item" onClick={handleCollapse}>
                <Link
                  className={`nav-link ${
                    location.pathname === "/about" ? "active active-style" : ""
                  }`}
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li className="nav-item" onClick={handleCollapse}>
                <Link
                  className={`nav-link ${
                    location.pathname === "/product"
                      ? "active active-style"
                      : ""
                  }`}
                  to="/product"
                >
                  Products
                </Link>
              </li>
              <li className="nav-item" onClick={handleCollapse}>
                <Link
                  className={`nav-link ${
                    location.pathname === "/contact"
                      ? "active active-style"
                      : ""
                  }`}
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
            <div className="contact-area-nav">
              <div>
                <Header />
              </div>
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-link" onClick={handleCollapse}>
                  <span className="header-send-query">
                    {" "}
                    To more info{" - "}&nbsp;&nbsp;
                    <a
                      className="link-mail"
                      href={`mailto:${contact?.email}?subject=NTI Product Inquiry`}
                    >
                      <i className="fa fa-paper-plane"></i>&nbsp; Send query
                    </a>
                  </span>
                </li>
                <li className="nav-link" onClick={handleClickTheme}>
                  <i className="fas fa-adjust"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Menubar;
