import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function ModalComponent(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.isShow}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.name}
          </Modal.Title>
          <Button onClick={props.closeModal} className="closeButton">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <img src={props.clickedImg} alt="img" />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.closeModal} className="closeButton">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
