import React from "react";
import "./Footers.css";
import { Link } from "react-router-dom";
import Logo from "./../assets/new-logo.png";
import { contact } from "../Common/Data";

export default function Footer() {
  const footerNavs = [
    {
      label: "Quick link",
      items: [
        {
          href: "/product",
          name: "Products",
        },
        {
          href: "/about",
          name: "About US",
        },
        {
          href: "/contact",
          name: "Contact",
        },
      ],
    },
    {
      label: "Contact",
      items: [
        {
          href: "#",
          name: "Email",
        },
        {
          href: "#",
          name: "Mobile",
        },
        {
          href: `mailto:${contact?.email}?subject=NTI Product Inquiry`,
          name: "To more info",
        },
      ],
    },
    {
      label: "Branch Office",
      items: [
        {
          href: "#",
          name: "80, Surendra Manik, Barkheda Pathani Bhopal (M.P.) 462022",
        },
        {
          href: "#",
          name: "socialMediaLink",
        },
      ],
    },
  ];
  return (
    <footer className="footer-style">
      <div className="container">
        <div className="row mt-3">
          {footerNavs.map((item, idx) => (
            <div
              className="col-sm-12 col-md-4 col-lg-4"
              key={idx}
              style={{ marginTop: "0px !important" }}
            >
              <div
                className="pr-8 headers mb-3 mt-3"
                style={{
                  borderBottom: "1px solid #d3d3d3",
                  borderTop: "1px solid #d3d3d3",
                  boxShadow: "0 3px 3px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h5 className="mt-3">{item.label}</h5>
              </div>
              {item.items.map((el, idx) => (
                <div key={idx} className="text-left">
                  {el.name === "socialMediaLink" ? (
                    <div className="social-media">
                      <ul>
                        <li
                          className="wow fadeInUp"
                          data-wow-duration="1s"
                          data-wow-offset="100"
                          data-wow-delay="0s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <a
                            className="socialIcon"
                            aria-label="first link"
                            target="_blank"
                            href="https://www.facebook.com/"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li
                          className="wow fadeInUp"
                          data-wow-duration="1s"
                          data-wow-offset="100"
                          data-wow-delay="0.1s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <a
                            className="socialIcon"
                            aria-label="first link"
                            target="_blank"
                            href="https://twitter.com/"
                          >
                            <i
                              className="fab fa-twitter"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li
                          className="wow fadeInUp"
                          data-wow-duration="1s"
                          data-wow-offset="100"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <a
                            className="socialIcon"
                            aria-label="first link"
                            target="_blank"
                            href="https://www.linkedin.com/"
                          >
                            <i
                              className="fab fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li
                          className="wow fadeInUp"
                          data-wow-duration="1s"
                          data-wow-offset="100"
                          data-wow-delay="0.4s"
                          style={{
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0s",
                            animationName: "fadeInUp",
                          }}
                        >
                          <a
                            className="socialIcon"
                            aria-label="first link"
                            target="_blank"
                            href="https://www.instagram.com/"
                          >
                            <i
                              className="fab fa-instagram"
                              aria-hidden="false"
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : el.name === "Email" ? (
                    <div className="linkcontact">
                      <i className="fa fa-envelope"></i>&nbsp;&nbsp;
                      <h6>{contact.email}</h6>
                    </div>
                  ) : el.name === "Mobile" ? (
                    <div className="linkcontact">
                      <i className="fa fa-phone"></i>&nbsp;&nbsp;
                      <h6>{contact.mobile}</h6>
                    </div>
                  ) : (
                    <Link className="footer-link" to={el.href}>
                      {el.name}
                    </Link>
                  )}
                  {el.name === "To more info" ? (
                    <a href={el.href} className="footer-link">
                      &nbsp;<i className="fa fa-paper-plane"></i>&nbsp; Send
                      query
                    </a>
                  ) : null}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="bottom-footer position-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
              <a href="/" style={{ float: "right" }}>
                <img className="logo-image" alt="Logo new tech" src={Logo} />
              </a>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 order-last order-md-first pull-left">
              <div className="copyright-text">
                <p>
                  Copyright © 2022{" -"}
                  {new Date().getFullYear()}{" "}
                  <a href="http://newtechinsulation.in/">newtechinsulation</a>.
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
        <a href="javascript:void(0)" className="back-to-top" title="Go To Top">
          <i className="fa fa-arrow-up"></i>
        </a>
      </div>
    </footer>
  );
}
