import React from "react";
import { title } from "../Common/Data";
const About = () => {
  return (
    <>
      <div className="container about about-area">
        <div className="main-title">
          <h2>
            About <span>{title}</span>
            <span className="bg-text">About {title}</span>
          </h2>
        </div>
        <div className="row about-container">
          <div className="col-sm-12 col-md-5 col-lg-5">
            <h4> {title}</h4>
            <p>
              New Tech Engineering (Insulation) specializes in the field of R&D
              production & has became the leader in the field of application for
              industrial energy saving heating technology in india & abroad.
              <br></br>
              <br></br>
              New Tech provide high quality completely reliable engineered
              thermal insulation solutions that are applicable across.
            </p>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 about-item">
                <div className="abt-text">
                  <p className="large-text">5+ Year </p>
                  <br></br>
                  <p className="small-text">
                    expirence providing insulation Service
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 about-item">
                <div className="abt-text">
                  <p className="large-text"> 4 to 7 Crore</p>
                  <br></br>
                  <p className="small-text">Annual turnover</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 about-item">
                <div className="abt-text">
                  <p className="large-text">50+</p>
                  <br></br>
                  <p className="small-text">employes</p>
                </div>
              </div>
              {/* <div className="col-sm-12 col-md-6 col-lg-6 about-item">
                <div className="abt-text">
                  <p className="large-text">100+</p>
                  <br></br>
                  <p className="small-text">Happy Customer & Clients</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="about-stats">
          <h4 className="stat-title">We are committed to offer</h4>
          <div className="row about-info">
            <div className="col-sm-10 col-md-10 col-lg-10">
              <p className="prog-title">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp;High quality products
              </p>
            </div>
          </div>
          <div className="row about-info">
            <div className="col-sm-10 col-md-10 col-lg-10">
              <p className="prog-title">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp;Cost effective pricing
              </p>
            </div>
          </div>
          <div className="row about-info">
            <div className="col-sm-10 col-md-10 col-lg-10">
              <p className="prog-title">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp;On time delivary
              </p>
            </div>
          </div>
        </div>
        <div className="about-stats">
          <h4 className="stat-title">New tech engineering good track record</h4>
          <div className="row about-info">
            <div className="col-sm-11 col-md-11 col-lg-11">
              <p className="prog-title">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp; 5+ year expirence in providing insulation
              </p>
            </div>
          </div>
          <div className="row about-info">
            <div className="col-sm-11 col-md-11 col-lg-11">
              <p className="prog-title">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp;Annual turnover- 4 to 7 crore
              </p>
            </div>
          </div>
          <div className="row about-info">
            <div className="col-sm-11 col-md-11 col-lg-11">
              <p className="prog-title">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp;50+ employes
              </p>
            </div>
          </div>
        </div>
        <div className="about-stats">
          <h4 className="stat-title">Design & development</h4>
          <div className="row about-info">
            <div className="col-sm-11 col-md-11 col-lg-11">
              <p className="prog-title">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp;Our team is working with safety, health & hygienic
                environment
              </p>
            </div>
          </div>
          <div className="row about-info">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <p className="prog-title">
                <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                &nbsp;&nbsp; CATIA Software & autocad for the development of 2D
                & 3D drawings
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
