import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Menubar from "./Components/Menubar";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Service from "./Pages/Service";
import ServiceDetails from "./Pages/ServiceDetails";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

function App() {
  return (
    <div className="App">
      <div className="fixed-top">
        {/* <Header /> */}
        <Menubar />
      </div>
      <div className="page-body">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/product" element={<Service />} />
          <Route exact path="/product/details" element={<ServiceDetails />} />
        </Routes>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
