import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Pressure_power_pump_insulation_cover_1 from "./../assets/Pressure_power_pump_insulation_cover_1.jpg";
import Removable_Insulation_Covers_1 from "./../assets/Removable_Insulation_Covers_1.jpg";
import Turbine_Insulation_Jackets_1 from "./../assets/Turbine_Insulation_Jackets_1.jpg";
import Silicone_Fiberglass_Cloth_1 from "./../assets/Silicone_Fiberglass_Cloth_1.jpg";
import Flange_Insulation_Covers_1 from "./../assets/Flange_Insulation_Covers_1.jpg";
import { service } from "../Common/Data";
import AppSlider from "./HomeSlider";

const Home = () => {
  return (
    <>
      <div className="carousal-home">
        <div className="desktop-view">
          <AppSlider />
        </div>
        {/* <Carousel
          data-bs-theme="dark"
          autoPlay={true}
          interval={2000}
          className="desktop-view"
        >
          {service &&
            service?.map((servc, index) => {
              return (
                <Carousel.Item key={index}>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <Carousel.Caption className="captionValue">
                        <h5>{servc.name}</h5>
                        <div className="paragraph-btn mt-5">
                          <p>{servc.description}</p>
                        </div>
                      </Carousel.Caption>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <img
                        className="d-block w-100 banner-img"
                        src={servc.img1}
                        alt="AI-ML-Development-Home-Page"
                      />
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel> */}
        <Carousel
          data-bs-theme="dark"
          autoPlay={true}
          interval={2000}
          className="mobile-view"
        >
          {service &&
            service?.map((servc, index) => {
              return (
                <Carousel.Item key={index}>
                  <div className="row">
                    <div>
                      <img
                        className="d-block w-100 banner-img"
                        src={servc.img1}
                        alt="AI-ML-Development-Home-Page"
                      />
                    </div>
                    <div>
                      <div className="mobile-view-text">
                        <h5 className="mt-3">{servc.name}</h5>
                        <div className="paragraph-btn mt-3">
                          <p>{servc.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
      <div className="about-section mt-5 mb-5">
        <div className="container mt-5">
          <div style={{ textAlign: "center" }}>
            <a href="/about">
              <h2 className="home-services-heading">About US</h2>
            </a>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h3 className="home-services-heading">NEW TECH ENGINEERING</h3>
              <p className="center-para">
                New Tech Engineering (Insulation){" "}
                <span>specializes in the field of R&D production</span> you are
                looking for, we can{" "}
                <span>
                  & has became the leader in the field of application for
                  industrial energy saving heating technology in india{" "}
                </span>{" "}
                & abroad.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
