import CERAMIC_FIBER_BLANKET1 from "../assets/CERAMIC_FIBER_BLANKET1.png";
import CERAMIC_FIBER_BLANKET2 from "../assets/CERAMIC_FIBER_BLANKET2.jpg";
import CERAMIC_FIBER_BLANKET3 from "../assets/CERAMIC_FIBER_BLANKET3.png";
import CERAMIC_FIBER_CLOTH1 from "../assets/CERAMIC_FIBER_CLOTH1.png";
import CERAMIC_FIBER_CLOTH2 from "../assets/CERAMIC_FIBER_CLOTH2.png";
import FIBERGLASS_CLOTH1 from "../assets/FIBERGLASS_CLOTH1.png";
import FIBERGLASS_CLOTH2 from "../assets/FIBERGLASS_CLOTH2.png";
import SILICON_FIBERGLASS_CLOTH1 from "../assets/SILICON_FIBERGLASS_CLOTH1.png";
import SILICON_FIBERGLASS_CLOTH2 from "../assets/SILICON_FIBERGLASS_CLOTH2.png";
import SILICON_FIBERGLASS_CLOTH3 from "../assets/SILICON_FIBERGLASS_CLOTH3.png";
import TURBINE_INSULATION1 from "../assets/Turbine-insulation1.jpg";
import INSULATION_PAD1 from "../assets/Insulation-pad1.jpg";
import INSULATION_PAD2 from "../assets/Insulation-pad2.jpg";
import Exhaust_insulation_jacket1 from "../assets/Exhaust-insulation-jacket1.jpg";
import Exhaust_insulation_jacket2 from "../assets/Exhaust-insulation-jacket2.jpg";
import Exhaust_insulation_jacket3 from "../assets/Exhaust-insulation-jacket3.jpg";
import valve_insulation_jacket1 from "../assets/Valve-insulation-jacket1.jpg";
import valve_insulation_jacket2 from "../assets/Valve-insulation-jacket2.jpg";
import valve_insulation_jacket3 from "../assets/Valve-insulation-jacket3.jpg";
import Turbine_Casing_Insulation_Jacket1 from "./../assets/Turbine-casting-insulation-jacket-1.jpg";
import Turbine_Casing_Insulation_Jacket2 from "./../assets/Turbine-casting-insulation-jacket-2.jpg";
import Turbine_Casing_Insulation_Jacket3 from "./../assets/Turbine-casting-insulation-jacket-3.jpg";
import Turbine_Insulation_Jacket1 from "./../assets/Turbine-Insulation-Jacket-1.jpg";
import Turbine_Insulation_Jacket2 from "./../assets/Turbine-Insulation-Jacket-2.jpg";
import Turbine_Insulation_Jacket3 from "./../assets/Turbine-Insulation-Jacket-3.jpg";
import Heating_Jacket1 from "./../assets/Heating-Jacket1.jpg";
import Heating_Jacket2 from "./../assets/Heating-Jacket2.jpg";
import Heating_Jacket3 from "./../assets/Heating-Jacket3.jpg";
import INSULATING_PAD1 from "./../assets/Insulating-Pad1.jpg";
import INSULATING_PAD2 from "./../assets/Insulating-Pad2.jpg";
import INSULATING_PAD3 from "./../assets/Insulating-Pad3.jpg";
import Unidirectional_fabric1 from "./../assets/Unidirectional_fabric1.jpg";
import Unidirectional_fabric2 from "./../assets/Unidirectional_fabric2.jpg";
import Unidirectional_fabric3 from "./../assets/Unidirectional_fabric3.jpg";
import Rockinsul_Lrb_Mattresses1 from "./../assets/Rockinsul_Lrb_Mattresses1.jpg";
import Rockinsul_Lrb_Mattresses2 from "./../assets/Rockinsul_Lrb_Mattresses2.jpg";
import Rockinsul_Lrb_Mattresses3 from "./../assets/Rockinsul_Lrb_Mattresses3.jpg";
import Chamber_Insulation_Jacket1 from "./../assets/Chamber_Insulation_Jacket1.jpg";
import Chamber_Insulation_Jacket2 from "./../assets/Chamber_Insulation_Jacket2.jpg";
import Chamber_Insulation_Jacket3 from "./../assets/Chamber_Insulation_Jacket3.jpg";
import Removable_Insulation_Covers1 from "./../assets/Removable_Insulation_Covers(Evaporating tank insulation cover)1.jpg";
import Removable_Insulation_Covers2 from "./../assets/Removable_Insulation_Covers(Evaporating tank insulation cover)2.jpg";
import Removable_Insulation_Covers3 from "./../assets/Removable_Insulation_Covers(Evaporating tank insulation cover)3.jpg";
import Glass_Wool_Insulation1 from "./../assets/Glass_Wool_Thermal_Insulation1.jpg";
import Glass_Wool_Insulation2 from "./../assets/Glass_Wool_Thermal_Insulation2.jpg";
import Glass_Wool_Insulation3 from "./../assets/Glass_Wool_Thermal_Insulation3.jpg";
import Puf_Pipe_Section1 from "./../assets/Puf_Pipe_Section1.jpg";
import Puf_Pipe_Section2 from "./../assets/Puf_Pipe_Section2.jpg";
import Puf_Pipe_Section3 from "./../assets/Puf_Pipe_Section1.jpg";
import Fire_Blanket1 from "./../assets/Fire_Blanket1.jpg";
import Fire_Blanket2 from "./../assets/Fire_Blanket2.jpg";
import Fire_Blanket3 from "./../assets/Fire_Blanket3.jpg";
import Pipeline_Insulation_Service1 from "./../assets/Pipeline_Insulation_Service1.jpg";
import Pipeline_Insulation_Service2 from "./../assets/Pipeline_Insulation_Service2.jpg";
import Pipeline_Insulation_Service3 from "./../assets/Pipeline_Insulation_Service3.jpg";
import Roof_Thermal_Insulation_Services1 from "./../assets/Roof_Thermal_Insulation_Services1.jpg";
import Roof_Thermal_Insulation_Services2 from "./../assets/Roof_Thermal_Insulation_Services2.jpg";
import Roof_Thermal_Insulation_Services3 from "./../assets/Roof_Thermal_Insulation_Services2.jpg";
import Wall_insulation_service1 from "./../assets/Wall_insulation_service1.jpg";
import Wall_insulation_service2 from "./../assets/Wall_insulation_service2.jpg";
import Wall_insulation_service3 from "./../assets/Wall_insulation_service2.jpg";
import Water_Tank_Insulation_Jacket1 from "./../assets/Water_Tank_Insulation_Jacket1.jpg";
import Water_Tank_Insulation_Jacket2 from "./../assets/Water_Tank_Insulation_Jacket2.jpg";
import Water_Tank_Insulation_Jacket3 from "./../assets/Water_Tank_Insulation_Jacket3.jpg";
import Engine_Exhaust_Insulation_Covers1 from "./../assets/Engine-Exhaust-Insulation-Covers-1.jpg";
import Engine_Exhaust_Insulation_Covers2 from "./../assets/Engine-Exhaust-Insulation-Covers-2.jpg";
import Engine_Exhaust_Insulation_Covers3 from "./../assets/Engine-Exhaust-Insulation-Covers-3.jpg";
import Removable_Insulation_Covers_1 from "./../assets/Removable_Insulation_Covers_1.jpg";
import Removable_Insulation_Covers_2 from "./../assets/Removable_Insulation_Covers_2.jpg";
import Removable_Insulation_Covers_3 from "./../assets/Removable_Insulation_Covers_3.jpg";
import Turbine_Insulation_Jackets_1 from "./../assets/Turbine_Insulation_Jackets_1.jpg";
import Turbine_Insulation_Jackets_2 from "./../assets/Turbine_Insulation_Jackets_2.jpg";
import Turbine_Insulation_Jackets_3 from "./../assets/Turbine_Insulation_Jackets_3.jpg";
import Silicone_Fiberglass_Cloth_1 from "./../assets/Silicone_Fiberglass_Cloth_1.jpg";
import Silicone_Fiberglass_Cloth_2 from "./../assets/Silicone_Fiberglass_Cloth_2.jpg";
import Silicone_Fiberglass_Cloth_3 from "./../assets/Silicone_Fiberglass_Cloth_3.jpg";
import Valve_energy_saving_Insulation_Jackets_1 from "./../assets/Valve_energy_saving_Insulation_Jackets_1.jpg";
import Valve_energy_saving_Insulation_Jackets_2 from "./../assets/Valve_energy_saving_Insulation_Jackets_2.jpg";
import Valve_energy_saving_Insulation_Jackets_3 from "./../assets/Valve_energy_saving_Insulation_Jackets_3.jpg";
import Flange_Insulation_Covers_1 from "./../assets/Flange_Insulation_Covers_1.jpg";
import Flange_Insulation_Covers_2 from "./../assets/Flange_Insulation_Covers_2.jpg";
import Flange_Insulation_Covers_3 from "./../assets/Flange_Insulation_Covers_3.jpg";
import Heater_Insulation_Jackets_1 from "./../assets/Heater_Insulation_Jackets_1.jpg";
import Heater_Insulation_Jackets_2 from "./../assets/Heater_Insulation_Jackets_2.jpg";
import Heater_Insulation_Jackets_3 from "./../assets/Heater_Insulation_Jackets_3.jpg";
import Unidirectional_Glass_Fiber_Cloth_1 from "./../assets/Unidirectional_Glass_Fiber_Cloth_1.jpg";
import Unidirectional_Glass_Fiber_Cloth_2 from "./../assets/Unidirectional_Glass_Fiber_Cloth_2.jpg";
import Unidirectional_Glass_Fiber_Cloth_3 from "./../assets/Unidirectional_Glass_Fiber_Cloth_3.jpg";
import Pressure_power_pump_insulation_cover_1 from "./../assets/Pressure_power_pump_insulation_cover_1.jpg";
import Pressure_power_pump_insulation_cover_2 from "./../assets/Pressure_power_pump_insulation_cover_2.jpg";
import Pressure_power_pump_insulation_cover_3 from "./../assets/Pressure_power_pump_insulation_cover_3.jpg";

//Service JSON data
export const service = [
  // {
  //   id: 1,
  //   name: "CERAMIC FIBER BLANKET",
  //   description:
  //     "Ceramic Blanket are available wide variety of densities and thickness. These blankets are made from long refractory fibers, binder free and free needled .They have excellent strength before and after heating.",
  //   img1: CERAMIC_FIBER_BLANKET1,
  //   img2: CERAMIC_FIBER_BLANKET2,
  //   img3: CERAMIC_FIBER_BLANKET3,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "Excellent insulating performance" },
  //     { id: 2, value: "Excellent thermal stability" },
  //     { id: 3, value: "Low heat storage" },
  //     { id: 4, value: "Resistance to thermal shock" },
  //     { id: 5, value: "Good sound absorption" },
  //   ],
  //   applications: [
  //     { id: 1, value: "Furnace and kiln linings" },
  //     { id: 2, value: "Boilers insulations" },
  //     { id: 3, value: "Reformer and heat lining" },
  //     { id: 4, value: "Cove oven hot repair" },
  //     { id: 5, value: "Expansion gap" },
  //     { id: 6, value: "Duct and pipe linings" },
  //     { id: 7, value: "Steam and gas turbine insulations." },
  //   ],
  //   Maximum_Temperature: "1260 degree celcius",
  //   Density: "64Kg/Cum",
  //   Thickness: "25mm",
  //   Packaging_type: "Roll",
  //   usages: "Heat Care Insulation",
  //   color: "As per your requirement ",
  //   material: "Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin: "Made in India",
  //   Note: "Materials are making as per your requirments.",
  // },
  // {
  //   id: 2,
  //   name: "FIBERGLASS CLOTH",
  //   description:
  //     "Fiberglass cloth is made of high strength E glass fiberglass yarn, it is divided into plain weave, twill weave or stain weave .It has good insulation, heat resistance, good corrosion resistance and tensile strength. It can be compatible with polyester, epoxy and vinyl resins, and is widely used in mica tape, fiberglass tape, aircraft industry, ship industry, chemical industry, military industry and sports goods, etc.",
  //   img1: FIBERGLASS_CLOTH1,
  //   img2: FIBERGLASS_CLOTH2,
  //   img3: FIBERGLASS_CLOTH2,
  //   feature: [
  //     { id: 1, value: "High intensity and quick wet out" },
  //     { id: 2, value: "Perfect designable character" },
  //     { id: 3, value: "Anti-corrosion and electric insulation" },
  //   ],
  //   applications: [
  //     { id: 1, value: "fiberglass tape" },
  //     { id: 2, value: "aircraft industry" },
  //     { id: 3, value: "ship industry" },
  //     { id: 4, value: "chemical industry" },
  //     { id: 5, value: "military industry" },
  //     { id: 6, value: "Heat Insulation" },
  //   ],
  //   Maximum_Temperature: "800 degree celcius.",
  //   Density: "",
  //   Thickness: "0.5-1mm",
  //   Packaging_type: "Roll",
  // usages:"Heat Care Insulation",
  //   color:"As per your requirement ",
  //   material:"Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin:"Made in India",
  //   Note: "Materials are making as per your requirments.",
  // },
  // {
  //   id: 3,
  //   name: "SILICON FIBERGLASS CLOTH",
  //   description:
  //     "The silicone coated fiberglass fabric. It is made of fiberglass fabric and silicone. It has good water resistance ,fire resisitance, chemical resistance, non-toxic and so on.",
  //   img1: SILICON_FIBERGLASS_CLOTH1,
  //   img2: SILICON_FIBERGLASS_CLOTH2,
  //   img3: SILICON_FIBERGLASS_CLOTH3,
  //   feature: [
  //     { id: 1, value: "Excellent waterproff" },
  //     { id: 2, value: "Water repellent" },
  //     { id: 3, value: "Chemical resistance" },
  //   ],
  //   applications: [
  //     { id: 1, value: "Insulation jackets mattress and pad" },
  //     { id: 2, value: "Fabric expansion joints" },
  //     { id: 3, value: "Fire doors and fire curtains" },
  //     { id: 4, value: "Welding/fire blankets" },
  //     { id: 5, value: "Other fire control system" },
  //   ],
  //   Maximum_Temperature: "265 degree celcius.",
  //   Density: "",
  //   Thickness: "0.25-3MM",
  //   Packaging_type: "Roll",
  // usages:"Heat Care Insulation",
  //   color:"As per your requirement ",
  //   material:"Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin:"Made in India",
  //   Note: "Materials are making as per your requirments.",
  // },
  // {
  //   id: 4,
  //   name: "CERAMIC FIBER CLOTH",
  //   description:
  //     "Ceramic fiber Cloth is made of high quality ceramic fiber yarn and formed into different products by different weaving processes. It can be used under a high temperature applications up to 1260° C. Normally, It is used as heat insulating materials and an excellent substitute for asbestos tape",
  //   img1: CERAMIC_FIBER_CLOTH1,
  //   img2: CERAMIC_FIBER_CLOTH2,
  //   img3: CERAMIC_FIBER_CLOTH2,
  //   feature: [
  //     { id: 1, value: "Good workability" },
  //     { id: 2, value: "Shock resistance" },
  //     { id: 3, value: "Good tightness" },
  //     { id: 4, value: "Good thermostability" },
  //     { id: 5, value: "Resistant to corrosion" },
  //     { id: 6, value: "Heat insulation" },
  //     { id: 7, value: "Erosion - resistance" },
  //     { id: 8, value: "Low heat capacity" },
  //     { id: 9, value: "Long working life" },
  //   ],
  //   applications: [
  //     { id: 1, value: "Heat radiation resistant" },
  //     { id: 2, value: "Valve fabricating" },
  //     { id: 3, value: "Flange sealing fabricating" },
  //     { id: 4, value: "Fire-proof door fabricating" },
  //     { id: 5, value: "Fire resistive material fabricating" },
  //     { id: 6, value: "Fire-proof suit fabricating" },
  //     { id: 7, value: "Heat insulation curtain" },
  //     { id: 8, value: "Safety/welding blankets" },
  //   ],
  //   Maximum_Temperature: "1260 degree celcius.",
  //   Density: "500Kg/M^3",
  //   Thickness: "0.25-3MM",
  //   Packaging_type: "Roll",
  // usages:"Heat Care Insulation",
  //   color:"As per your requirement ",
  //   material:"Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin:"Made in India",
  //   Note: "Materials are making as per your requirments.",
  // },
  // {
  //   id: 5,
  //   name: "TURBINE INSULATION",
  //   description:
  //     "Turbine housings, particularly turbine housings of steam turbines, are equipped with a thermal insulation. The thermal insulation is used to minimize the loss of heat via the turbine housing into the environment, since thermal losses result in reduced efficiency.",
  //   img1: TURBINE_INSULATION1,
  //   img2: TURBINE_INSULATION1,
  //   img3: TURBINE_INSULATION1,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "We provide the heavy duty Turbine insulation pad." },
  //   ],
  //   applications: [
  //     { id: 1, value: "Insulate engine parts." },
  //     { id: 2, value: "Exhaust pipe and components." },
  //     { id: 3, value: "Industrial process piping and machinery." },
  //     { id: 4, value: "Diesel engines" },
  //     { id: 5, value: "Power generation plants" },
  //   ],
  //   Maximum_Temperature: "1260 degree celcius.",
  //   Density: "500Kg/M^3",
  //   Thickness: "0.25-3MM",
  //   usages: "Heat Care Insulation",
  //   color: "As per your requirement ",
  //   material: "Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin: "Made in India",
  //   Packaging_type: "Roll",
  //   Note: "Materials are making as per your requirments.",
  // },
  // {
  //   id: 6,
  //   name: "INSULATION PAD (FIBER GLASS CLOTH)",
  //   description: `Insulation pad (Fiberglass cloth) are available in four different sizes`,
  //   img1: INSULATION_PAD1,
  //   img2: INSULATION_PAD1,
  //   img3: INSULATION_PAD1,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "1000mm X 600mm X 50mm(T)" },
  //     { id: 2, value: "1000mm X 1000mm X 50mm(T)" },
  //     { id: 3, value: "1000mm X 6000mm X 100mm(T)" },
  //     { id: 4, value: "1000mm X 100mm X 100mm(T)" },
  //   ],
  //   applications: [],
  //   Maximum_Temperature: "",
  //   Density: "",
  //   usages: "Heat Care Insulation",
  //   color: "As per your requirement ",
  //   material: "Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin: "Made in India",
  //   Thickness: "",
  //   Packaging_type: "",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 7,
  //   name: "INSULATION PAD (SILICON IMPERGNATED FIBREGLASS)",
  //   description: `Insulation pad (Silicon impergnated fiberglass) are available in four different sizes`,
  //   img1: INSULATION_PAD2,
  //   img2: INSULATION_PAD2,
  //   img3: INSULATION_PAD2,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "1000mm X 600mm X 50mm(T)" },
  //     { id: 2, value: "1000mm X1000mm X 50mm(T)" },
  //     { id: 3, value: "1000mm X 6000mm X 100mm(T)" },
  //     { id: 4, value: "1000mm X 100mm X 100mm(T)" },
  //   ],
  //   applications: [],
  //   Maximum_Temperature: "",
  //   usages: "Heat Care Insulation",
  //   color: "As per your requirement ",
  //   material: "Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "",
  //   Packaging_type: "",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 8,
  //   name: "Insulating Pad",
  //   description: `Turbine Insulation pad used is generally Ceramic wool blankets of heavy density. pads are made from ceramic fiberglass cloth and ceramic Blanket material and are stitched with Heat resisting threads to fit the contours of Gas turbine. These pillows are fitted like a jig-saw puzzle to take the shape of turbine surface`,
  //   img1: INSULATING_PAD1,
  //   img2: INSULATING_PAD2,
  //   img3: INSULATING_PAD3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "1260 degree celcius",
  //   usages: "Heat Care Insulation",
  //   color: "As per your requirement ",
  //   material: "Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "12.5-100 mm",
  //   Packaging_type: "",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 9,
  //   name: "Unidirectional fabric",
  //   description: `Glass fibers and fiberglass cloth are X commonly used in composite construction, including materials used in boat building. Light weight fiberglass cloth is used with a resin to produce a waterproof surface. Heavier woven fiberglass fabric can be used for greater strength and rigidity. Glass fibers and fiberglass cloth are also used to create combination or specialty fabrics. Glass fibers and fiberglass cloth may be combined with carbon fiber or aramid fibers to make laminates and moldable fabrics for canoes, kayaks, and other high strength, low weight applications.`,
  //   img1: Unidirectional_fabric1,
  //   img2: Unidirectional_fabric2,
  //   img3: Unidirectional_fabric3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "",
  //   usages: "Cover, High Strength Sheet, construction",
  //   color: "White",
  //   material: "Ceramic Fiberglass cloth and ceramic Blanket",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "",
  //   Packaging_type: "Roll",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 10,
  //   name: "Rockwool Resin Bonded Mattress",
  //   description: `ROCKINSUL LRB Mattresses are flexible rock fiber mattresses, stitched with wire mesh and manufactured from stable rock fibers bonded with a minimum quantity of thermosetting resin binder. ROCKINSUL LRB Mattresses are light weight, strong, resilient, easy to handle and cut to suit intricate shapes.`,
  //   img1: Rockinsul_Lrb_Mattresses1,
  //   img2: Rockinsul_Lrb_Mattresses2,
  //   img3: Rockinsul_Lrb_Mattresses3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "750 degree celcius",
  //   usages:
  //     "use in large process pipe-work, tanks, vessels boilers and ducts to provide unrivalled combination o",
  //   color: "yellow",
  //   material: "",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "	50,75,100mm",
  //   Packaging_type: "HDPE Bag",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 11,
  //   name: "Removable Insulation Jacket(Chamber Insulation Jacket)",
  //   description: `The Chambers jacket provides incredible warmth and weather protection in a hooded, quilted design. This thermal layer can stand up to harsh winds and wintery conditions thanks to breathable insulation on the inside and durable, water-repellent coating on the outside.`,
  //   img1: Chamber_Insulation_Jacket1,
  //   img2: Chamber_Insulation_Jacket2,
  //   img3: Chamber_Insulation_Jacket3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "500 degree Celsius",
  //   usages:
  //     "reduce the amount of heat your body loses to the atmosphere in cold conditions.",
  //   color: "Grey/red    ",
  //   material: "",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "25mm",
  //   Packaging_type: "",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 12,
  //   name: "Removable Insulation Covers(Evaporating tank insulation cover)",
  //   description: `Evaporating tank insulation cover or jacket provided according to your specific requirements we provide cutout according to your request fixed with proper heavy eyelet ring`,
  //   img1: Removable_Insulation_Covers1,
  //   img2: Removable_Insulation_Covers3,
  //   img3: Removable_Insulation_Covers2,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "350degree celcius",
  //   usages: "Pharmaceutical use",
  //   color: "As per your request",
  //   material: "Silicon fiberglass fabric & ceramic fiber blanket",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "25mm",
  //   Packaging_type: "Box",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 13,
  //   name: "Glass Wool Insulation",
  //   description: `Glass wool is an insulating material made from fibres of glass arranged using a binder into a texture similar to wool. The process traps many small pockets of air between the glass, and these small air pockets result in high thermal insulation properties.`,
  //   img1: Glass_Wool_Insulation1,
  //   img2: Glass_Wool_Insulation2,
  //   img3: Glass_Wool_Insulation3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "200-400 degree Celsius",
  //   usages:
  //     "Dry wall insulation, Roof insulation, Ducting, Prevents sound transmission.",
  //   color: "Yellow",
  //   material: "Glass wool",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "25-150mm",
  //   Packaging_type: "Rolls",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 14,
  //   name: "Puf Pipe Section (Rockwool Insulation Pipe Sections)",
  //   description: `Rock wool Pipe Sections are non-combustible, easy to cut, fit, handle and has high levels of thermal efficiency and strength. `,
  //   img1: Puf_Pipe_Section1,
  //   img2: Puf_Pipe_Section2,
  //   img3: Puf_Pipe_Section3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "750 degree celcius",
  //   usages: "Thermal Insulation OF PIPE",
  //   color: "Yellow",
  //   material: "Glass wool",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "50mm & 100mm insulation",
  //   Packaging_type: "Rolls",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 15,
  //   name: "Fire Blanket",
  //   description: `fire blanket is a simple fire safety appliance designed to tackle small contained fires usually found in a kitchen. A traditional fire blanket is constructed from a sheet of woven fire-resistant material and is stored in a wall mounted container.`,
  //   img1: Fire_Blanket1,
  //   img2: Fire_Blanket2,
  //   img3: Fire_Blanket3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "1300 Degree Celsius",
  //   usages: "Fire fighting, welding purpose etc.",
  //   color: "As per your requirement",
  //   material: "Ceramic Fiber    ",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "1-3 mm    ",
  //   Packaging_type: "Rolls",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 16,
  //   name: "Insulation Products & Services (Pipeline Insulation Service)",
  //   description: `All type of Insulation services are provided.`,
  //   img1: Pipeline_Insulation_Service1,
  //   img2: Pipeline_Insulation_Service2,
  //   img3: Pipeline_Insulation_Service3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "1300 Degree Celsius",
  //   usages: "Industrial",
  //   color: "As per your requirement",
  //   material: "All type of thermal",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "As per requirement",
  //   Packaging_type: "Rolls",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 17,
  //   name: "Roof Thermal Insulation Services",
  //   description: `Roof insulation controls heat and sound. 	Roof heat insulation service.`,
  //   img1: Roof_Thermal_Insulation_Services1,
  //   img2: Roof_Thermal_Insulation_Services2,
  //   img3: Roof_Thermal_Insulation_Services3,
  //   sub_category: [],
  //   feature: [{ id: 1, value: "	Roof heat insulation service." }],
  //   applications: [],
  //   Maximum_Temperature: "1300 Degree Celsius",
  //   usages: "Commercial",
  //   color: "As per your requirement",
  //   material: "	Glass wool, Rockwool, bubble wrap sheet etc.",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "As per requirement",
  //   Packaging_type: "Rolls",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 18,
  //   name: "Wall Insulation Services",
  //   description: `Heat and sound insulation`,
  //   img1: Wall_insulation_service1,
  //   img2: Wall_insulation_service2,
  //   img3: Wall_insulation_service3,
  //   sub_category: [],
  //   feature: [{ id: 1, value: "	Heat and sound insulation" }],
  //   applications: [],
  //   Maximum_Temperature: "",
  //   usages: "wall insulation for heat and sound control",
  //   color: "As per your requirement",
  //   material: "",
  //   country_of_origin: "Made in India",
  //   Density: "",
  //   Thickness: "As per requirement",
  //   Packaging_type: "",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },

  // {
  //   id: 19,
  //   name: "Turbine Casing Insulation Jacket",
  //   description:
  //     "Turbine Insulation pad used is generally Ceramic wool blankets of heavy density. pads are made from ceramic fiberglass cloth and ceramic Blanket material and are stitched with Heat resisting threads to fit the contours of Gas turbine. These pillows are fitted like a jig-saw puzzle to take the shape of turbine surface.",
  //   img1: Turbine_Casing_Insulation_Jacket1,
  //   img2: Turbine_Casing_Insulation_Jacket2,
  //   img3: Turbine_Casing_Insulation_Jacket3,
  //   Maximum_Temperature: "1260 Degree Celsius",
  //   Density: "",
  //   sub_category: [],
  //   Thickness: "12.5-100 mm",
  //   Packaging_type: "",
  //   material: "CERAMIC FIBERGLASS CLOTH & CERAMIC BLANKET",
  //   usages: "Turbine Casing",
  //   color: "",
  //   country_of_origin: "	Made in India",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },

  // {
  //   id: 21,
  //   name: "EXHAUST INSULATION JACKET",
  //   description: `Engine Exhaust Insulation Jackets are essential for effective performance and safety for piping, exhaust systems and personnel due to the limited space afforded in engine rooms, galleys and gangways. Engine Exhaust Insulation Jackets reduce ambient heat load, protect heat sensitive sensors and equipment, assist in fire prevention, maximize effectiveness of exhaust catalytic purifiers and filters and reduce risk of injury due to contact with heated surfaces.`,
  //   img1: Exhaust_insulation_jacket1,
  //   img2: Exhaust_insulation_jacket2,
  //   img3: Exhaust_insulation_jacket3,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "Max. Temperature = 650 degree celcius" },
  //     { id: 2, value: "Colour = Grey/Silver" },
  //     { id: 3, value: "Thickness = 25-50mm" },
  //     { id: 4, value: "Impergenated = Silicone Impergenated" },
  //     { id: 5, value: "Material = Ceramic Fiber, Silicone Cloth" },
  //   ],
  //   applications: [
  //     {
  //       id: 1,
  //       value: "Exhaust Insulation Jackets reduce ambient heat load.",
  //     },
  //     { id: 2, value: "Protect heat sensitive sensors and equipment." },
  //     { id: 3, value: "Assist in fire prevention." },
  //     {
  //       id: 4,
  //       value:
  //         "Maximize effectiveness of exhaust catalytic purifiers and filters.",
  //     },
  //     {
  //       id: 5,
  //       value: "Reduce risk of injury due to contact with heated surfaces.",
  //     },
  //   ],
  //   Maximum_Temperature: "650 degree celcius",
  //   Density: "",
  //   Thickness: "25-50mm",
  //   material: "silicone coated fiberglass cloth & ceramic blanket",
  //   color: "Grey/Red",
  //   usages: "high temperature casing",
  //   Packaging_type: "",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 22,
  //   name: "VALVE INSULATION JACKET",
  //   description: `It's a type of insulation cover that provides thermal insulation for a valve. Valve insulation jacket can prevent heat loss, limit condensation on cold services, protect personnel from high heat and also protect the assests form freezing They also provide environmental, personnel, and passive fire protection. Insulated valve jackets are reusable coverings that reduce heat loss and conserve energy in uninsulated valves.`,
  //   img1: valve_insulation_jacket1,
  //   img2: valve_insulation_jacket2,
  //   img3: valve_insulation_jacket3,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "Max. Temperature = 850 degree celcius" },
  //     { id: 2, value: "Colour = Grey/Silver" },
  //     { id: 3, value: "Thickness = 25-50mm" },
  //     { id: 5, value: "Material = Ceramic Fiber, Silicone Cloth" },
  //   ],
  //   applications: [
  //     {
  //       id: 1,
  //       value:
  //         "Lower the surface temperature of exposed valves in your boiler room",
  //     },
  //     { id: 2, value: "Prevent heat loss from their hot components." },
  //     {
  //       id: 3,
  //       value: "Resulting in reduced GHG emissions & lower energy bills.",
  //     },
  //     {
  //       id: 4,
  //       value: "Reduce risk of injury due to contact with heated surfaces.",
  //     },
  //     {
  //       id: 5,
  //       value:
  //         "Cool the ambient temperature by covering hot components that radiate heat like valves, boilers, pipes, and more.",
  //     },
  //   ],
  //   Maximum_Temperature: "850 Degree Celsius",
  //   Density: "",
  //   Thickness: "25-50mm",
  //   material: "Ceramic Blanket And Silicon Cloth",
  //   Packaging_type: "",
  //   color: "Grey",
  //   usages: "Valve insulation",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 23,
  //   name: "Water Tank Insulation Jacket",
  //   description: `It's a type of insulation cover that provides thermal insulation for a valve. Valve insulation jacket can prevent heat loss, limit condensation on cold services, protect personnel from high heat and also protect the assests form freezing They also provide environmental, personnel, and passive fire protection. Insulated valve jackets are reusable coverings that reduce heat loss and conserve energy in uninsulated valves.`,
  //   img1: Water_Tank_Insulation_Jacket1,
  //   img2: Water_Tank_Insulation_Jacket2,
  //   img3: Water_Tank_Insulation_Jacket3,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "It’s a six layer insulation Jacket" },
  //     { id: 2, value: "Two Layer UV protection" },
  //     { id: 3, value: "Can be easily installed by anyone" },
  //     {
  //       id: 5,
  //       value:
  //         "Can be easily unfolded during Monsoon season for longer life.( Not Compulsory)",
  //     },
  //     {
  //       id: 6,
  //       value:
  //         "Specifically designed to adjust inlet/Outlet and overflow pipes and provided with top cover.",
  //     },
  //     { id: 7, value: "to maintain water hot and cold in weather" },
  //   ],
  //   applications: [
  //     {
  //       id: 1,
  //       value:
  //         "Lower the surface temperature of exposed valves in your boiler room",
  //     },
  //     { id: 2, value: "Prevent heat loss from their hot components." },
  //     {
  //       id: 3,
  //       value: "Resulting in reduced GHG emissions & lower energy bills.",
  //     },
  //     {
  //       id: 4,
  //       value: "Reduce risk of injury due to contact with heated surfaces.",
  //     },
  //     {
  //       id: 5,
  //       value:
  //         "Cool the ambient temperature by covering hot components that radiate heat like valves, boilers, pipes, and more.",
  //     },
  //   ],
  //   Maximum_Temperature: "45 Degree Celcius",
  //   Density: "",
  //   Thickness: "	25 mm",
  //   material: "Ceramic Fiber",
  //   Packaging_type: "",
  //   color: "Grey",
  //   usages: "Water Tanks Temperature Maintain",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 24,
  //   name: "Engine Exhaust Insulation Covers",
  //   description: `Engine Exhaust Insulation Covers, are designed to withstand harsh conditions, including temperatures upto 16500C. These insulation covers prevent 80% heat loss and maintain constant inside pipe temperature. These are alternative replacements to asbestos, poly vinyl, rock wool, coated covers or any other conventional aluminum cliding, They eliminate the need to re insulate the heat generating equipments /hot pipes for routine maintenance or during repairing.`,
  //   img1: Engine_Exhaust_Insulation_Covers1,
  //   img2: Engine_Exhaust_Insulation_Covers2,
  //   img3: Engine_Exhaust_Insulation_Covers3,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "It’s a six layer insulation Jacket" },
  //     { id: 2, value: "Two Layer UV protection" },
  //     { id: 3, value: "Can be easily installed by anyone" },
  //     {
  //       id: 5,
  //       value:
  //         "Can be easily unfolded during Monsoon season for longer life.( Not Compulsory)",
  //     },
  //     {
  //       id: 6,
  //       value:
  //         "Specifically designed to adjust inlet/Outlet and overflow pipes and provided with top cover.",
  //     },
  //     { id: 7, value: "to maintain water hot and cold in weather" },
  //   ],
  //   applications: [
  //     {
  //       id: 1,
  //       value:
  //         "Lower the surface temperature of exposed valves in your boiler room",
  //     },
  //     { id: 2, value: "Prevent heat loss from their hot components." },
  //     {
  //       id: 3,
  //       value: "Resulting in reduced GHG emissions & lower energy bills.",
  //     },
  //     {
  //       id: 4,
  //       value: "Reduce risk of injury due to contact with heated surfaces.",
  //     },
  //     {
  //       id: 5,
  //       value:
  //         "Cool the ambient temperature by covering hot components that radiate heat like valves, boilers, pipes, and more.",
  //     },
  //   ],
  //   Maximum_Temperature: "1500",
  //   Density: "",
  //   Thickness: "As per your request",
  //   material: "Ceramic fiber cloth and silicon impregnated fiberglass cloth",
  //   Packaging_type: "",
  //   color: "Grey",
  //   usages:
  //     "Engine compartment insulation keeps heat and noise in the engine bay from reaching a vehicle's interior. Thermal insulation protects drivers, operators, and passengers from high temperatures that can also damage on-board electronics such as GPS tracking systems",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 25,
  //   name: "Turbine Insulation Jacket",
  //   description: `Turbine Insulation Covers, are designed to withstand harsh conditions, including temperatures upto 1650OC. These insulation covers prevents 80% heat loss and maintain constant inside pipe temperature.`,
  //   img1: Turbine_Insulation_Jacket1,
  //   img2: Turbine_Insulation_Jacket2,
  //   img3: Turbine_Insulation_Jacket3,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "It’s a six layer insulation Jacket" },
  //     { id: 2, value: "Two Layer UV protection" },
  //     { id: 3, value: "Can be easily installed by anyone" },
  //     {
  //       id: 5,
  //       value:
  //         "Can be easily unfolded during Monsoon season for longer life.( Not Compulsory)",
  //     },
  //     {
  //       id: 6,
  //       value:
  //         "Specifically designed to adjust inlet/Outlet and overflow pipes and provided with top cover.",
  //     },
  //     { id: 7, value: "to maintain water hot and cold in weather" },
  //   ],
  //   applications: [
  //     {
  //       id: 1,
  //       value:
  //         "Lower the surface temperature of exposed valves in your boiler room",
  //     },
  //     { id: 2, value: "Prevent heat loss from their hot components." },
  //     {
  //       id: 3,
  //       value: "Resulting in reduced GHG emissions & lower energy bills.",
  //     },
  //     {
  //       id: 4,
  //       value: "Reduce risk of injury due to contact with heated surfaces.",
  //     },
  //     {
  //       id: 5,
  //       value:
  //         "Cool the ambient temperature by covering hot components that radiate heat like valves, boilers, pipes, and more.",
  //     },
  //   ],
  //   Maximum_Temperature: "1200 Degree Celsius",
  //   Density: "",
  //   Thickness: "25-50 mm",
  //   material: "Ceramic Fiber",
  //   Packaging_type: "",
  //   color: "",
  //   usages: "High Temperature Casing",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  // {
  //   id: 26,
  //   name: "Heating Jacket",
  //   description: `These covers are made from various grades of industrial woven fiberglass fabric, encapsulating high temperature insulation. Polymer impregnated fiberglass cloth jacketing provides excellent oil, moisture and abrasion resistance, while PTFE coated fiberglass is recommended for corrosive environments.`,
  //   img1: Heating_Jacket1,
  //   img2: Heating_Jacket2,
  //   img3: Heating_Jacket3,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "It’s a six layer insulation Jacket" },
  //     { id: 2, value: "Two Layer UV protection" },
  //     { id: 3, value: "Can be easily installed by anyone" },
  //     {
  //       id: 5,
  //       value:
  //         "Can be easily unfolded during Monsoon season for longer life.( Not Compulsory)",
  //     },
  //     {
  //       id: 6,
  //       value:
  //         "Specifically designed to adjust inlet/Outlet and overflow pipes and provided with top cover.",
  //     },
  //     { id: 7, value: "to maintain water hot and cold in weather" },
  //   ],
  //   applications: [
  //     {
  //       id: 1,
  //       value:
  //         "Lower the surface temperature of exposed valves in your boiler room",
  //     },
  //     { id: 2, value: "Prevent heat loss from their hot components." },
  //     {
  //       id: 3,
  //       value: "Resulting in reduced GHG emissions & lower energy bills.",
  //     },
  //     {
  //       id: 4,
  //       value: "Reduce risk of injury due to contact with heated surfaces.",
  //     },
  //     {
  //       id: 5,
  //       value:
  //         "Cool the ambient temperature by covering hot components that radiate heat like valves, boilers, pipes, and more.",
  //     },
  //   ],
  //   Maximum_Temperature: "1200 Degree Celsius",
  //   Density: "",
  //   Thickness: "25-50 mm",
  //   material: "Ceramic Fiber",
  //   Packaging_type: "",
  //   color: "",
  //   usages: "High Temperature Casing",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  {
    id: 27,
    name: "Removable Insulation Covers",
    description: `Providing you the best range of Valve Insulation Cover, Reusable Turbine Insulation Cover, Grey Flexible Thermal Insulation jackets, Turbine Insulation Covers and Thermal Insulation Cover with effective & timely delivery.`,
    img1: Removable_Insulation_Covers_1,
    img2: Removable_Insulation_Covers_2,
    img3: Removable_Insulation_Covers_3,
    sub_category: [],
    feature: [],
    applications: [
      {
        id: 1,
        value:
          "New tech engineers Valve insulation cover also known as valve insulation blankets, are designed and manufactured to provide a custom fit for all types and models of industrial valves, including:",
      },
      { id: 2, value: "Butterfly Valves" },
      {
        id: 3,
        value: "Control Valves",
      },
      {
        id: 4,
        value: "Gate Valves",
      },
      {
        id: 5,
        value: "Globe Valves",
      },
      {
        id: 6,
        value: "Plug Valves",
      },
      {
        id: 7,
        value: "Pressure-reducing Valves",
      },
      {
        id: 8,
        value:
          "Whether your application requires maintaining constant process temperatures, protecting components from extremes of temperature and weather, or protecting personnel, Fit Tight valve insulation covers are custom designed and manufactured to meet your requirements",
      },
    ],
    Maximum_Temperature: "1200 degree celcius",
    Density: "96 to 128 kg/m^3",
    Thickness: "12mm to 50mm",
    material: "High temperature clothes",
    Packaging_type: "",
    color: "As per your request",
    usages: "High temperature casing",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  {
    id: 28,
    name: "Turbine Insulation Jackets",
    description: `Turbine Insulation pad used is generally Ceramic wool blankets of heavy density. pads are made from ceramic fiberglass cloth and ceramic Blanket material and are stitched with Heat resisting threads to fit the contours of Gas turbine. These pillows are fitted like a jig-saw puzzle to take the shape of turbine surface.`,
    img1: Turbine_Insulation_Jackets_1,
    img2: Turbine_Insulation_Jackets_2,
    img3: Turbine_Insulation_Jackets_3,
    sub_category: [],
    feature: [],
    applications: [],
    Maximum_Temperature: "1260 Degree Celsius",
    Density: "",
    Thickness: "12.5-100 mm",
    material: "	CERAMIC FIBERGLASS CLOTH & CERAMIC BLANKET",
    Packaging_type: "",
    color: "As per your request",
    usages: "Turbine Casing",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  // {
  //   id: 29,
  //   name: "Silicone Fiberglass Cloth",
  //   description: `The silicone coated fiberglass fabric. Itis made of fiberglass fabric and silicone.It has good water resistance, fireresisitance, chemical resistance,non-toxic and so on.`,
  //   img1: Silicone_Fiberglass_Cloth_1,
  //   img2: Silicone_Fiberglass_Cloth_2,
  //   img3: Silicone_Fiberglass_Cloth_3,
  //   sub_category: [],
  //   feature: [
  //     { id: 1, value: "Excellent waterproff" },
  //     { id: 2, value: "Water repellent" },
  //     { id: 3, value: "Chemical resistance" },
  //   ],
  //   applications: [
  //     {
  //       id: 5,
  //       value: "Insulation jackets mattress and pad",
  //     },
  //     {
  //       id: 6,
  //       value: "Fabric expansion joints",
  //     },
  //     {
  //       id: 7,
  //       value: "Fire doors and fire curtains",
  //     },
  //     {
  //       id: 8,
  //       value: "Welding/fire blankets",
  //     },
  //     {
  //       id: 1,
  //       value: "Other fire control system",
  //     },
  //   ],
  //   Maximum_Temperature: "265 Degree Celsius",
  //   Density: "Silicon Rubber Coated",
  //   Thickness: "1 mm",
  //   material: "	Fiberglass",
  //   Packaging_type: "Roll",
  //   color: "As per your request",
  //   usages: "",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  {
    id: 30,
    name: "Valve energy saving Insulation Jackets",
    description: `It's a type of insulation cover that provides thermal insulation for a valve. Valve insulation jacket can prevent heat loss, limit condensation on cold services, protect personnel from high heat and also protect the assests form freezing.`,
    img1: Valve_energy_saving_Insulation_Jackets_1,
    img2: Valve_energy_saving_Insulation_Jackets_2,
    img3: Valve_energy_saving_Insulation_Jackets_3,
    sub_category: [],
    feature: [],
    applications: [],
    Maximum_Temperature: "850 Degree Celsius",
    Density: "Silicon Rubber Coated",
    Thickness: "12-50mm",
    material: "	Ceramic Blanket And Silicon Cloth",
    Packaging_type: "",
    color: "Grey",
    usages: "Valve insulation",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  {
    id: 31,
    name: "Flange Insulation Covers",
    description: `flange insulation covers are a reliable and cost effective solution to the specific insulation needs of your piping system. Our thermal insulation covers are custom designed and manufactured to meet the requirements of your application where increased energy efficiency, temperature control, or protection of personnel or manufacturing environment system is a priority.Flange joints in a variety of industrial and institutional settings are a vital component of piping systems and are manufactured in materials that meet the requirements of the application, from carbon steel, stainless steel, or aluminum to titanium or even exotic alloys.`,
    img1: Flange_Insulation_Covers_1,
    img2: Flange_Insulation_Covers_2,
    img3: Flange_Insulation_Covers_3,
    sub_category: [],
    feature: [],
    applications: [],
    Maximum_Temperature: "800 Degree Celsius",
    Density: "Silicon Rubber Coated",
    Thickness: "12-50mm",
    material: "	Insulation Covers",
    Packaging_type: "",
    color: "Grey",
    usages: "Temperature control / energy saving",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  {
    id: 32,
    name: "Heater Insulation Jackets",
    description: `Barrel Jackets can be used on any brand of Injection Molding Machine. Their primary use lies in Saving Money by insulating the barrel from the Heat Loss. This in turn requires the Heater Bands to cycle less and lower electricity consumption.
    By using these insulation jackets, you establish a thermal barrier that impedes temperature dissipation released to its surrounding which permits us to keep the heat generated where it should be, inside the barrel of the machinery.
    Insulating Material – High Density Ceramic Fiber Wool, resistant to high temperature while maintaining its original state, durability and flexibility throughout its useful life. Resists temperature upto 1250 degree C`,
    img1: Heater_Insulation_Jackets_1,
    img2: Heater_Insulation_Jackets_2,
    img3: Heater_Insulation_Jackets_3,
    sub_category: [],
    feature: [],
    applications: [],
    Maximum_Temperature: "800 degree celcius",
    Density: "Silicon Rubber Coated",
    Thickness: "12mm to 50mm",
    material: "	Cerawool,mineral wool, high temperature cloth etc",
    Packaging_type: "",
    color: "As per your request",
    usages: "Injection molding Machine, All types extrusion machine",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
  // {
  //   id: 33,
  //   name: "Unidirectional Glass Fiber Cloth",
  //   description: `Glass fibers and fiberglass cloth are X commonly used in composite construction, including materials used in boat building. Light weight fiberglass cloth is used with a resin to produce a waterproof surface. Heavier woven fiberglass fabric can be used for greater strength and rigidity. Glass fibers and fiberglass cloth are also used to create combination or specialty fabrics. Glass fibers and fiberglass cloth may be combined with carbon fiber or aramid fibers to make laminates and moldable fabrics for canoes, kayaks, and other high strength, low weight applications.`,
  //   img1: Unidirectional_Glass_Fiber_Cloth_1,
  //   img2: Unidirectional_Glass_Fiber_Cloth_2,
  //   img3: Unidirectional_Glass_Fiber_Cloth_3,
  //   sub_category: [],
  //   feature: [],
  //   applications: [],
  //   Maximum_Temperature: "",
  //   Density: "Unidirectional",
  //   Thickness: "",
  //   material: "	Cerawool,mineral wool, high temperature cloth etc",
  //   Packaging_type: "Roll",
  //   color: "White",
  //   usages: "	Cover, High Strength Sheet, construction",
  //   Note: "Sizes are provided as per your requirements/Drawing",
  // },
  {
    id: 34,
    name: "Pressure power pump insulation cover",
    description: `New Tech Engineers Pressure Power Pump insulation covers are custom-created by designers and field service personnel who know exactly what to consider in fabricating the highest-quality, best-fitting removable cover for each pump. Custom pump insulation covers provide the best fit no matter what size or configuration is needed, and they are designed and manufactured for complete coverage and protection. But Fit Tight pump insulation covers are durable enough to be easily removed and replaced by your maintenance team for inspection and service`,
    img1: Pressure_power_pump_insulation_cover_1,
    img2: Pressure_power_pump_insulation_cover_2,
    img3: Pressure_power_pump_insulation_cover_3,
    sub_category: [],
    feature: [{ id: 1, value: "DURABLE" },
    { id: 2, value: "LONG-LASTING" },
    { id: 3, value: "PROTECTION FOR YOUR EQUIPMENT" },],
    applications: [
      {
        id: 5,
        value: "Our pump insulation blankets (or insulation covers) are designed and engineered to serve as the insulation solution for any size, shape or variety of pump out there today",
      },
      {
        id: 6,
        value: " Fit Tight design engineers will visit your site and evaluate your pump insulation cover requirements; then we design and manufacture removable insulation covers to provide durable, long-lasting protection for your equipment",
      },
    ],
    Maximum_Temperature: "1200 degree celcius",
    Density: "Unidirectional",
    Thickness: ">20 mm",
    material: "	High temp. fabric,Wool With ss wire mesh",
    Packaging_type: "",
    color: "As per your request",
    usages: "	For heat casing",
    Note: "Sizes are provided as per your requirements/Drawing",
  },
];

//Contact Details
export const contact = {
  email: "info@newtechinsulation.in",
  address: "80, Surendra Manik, Barkheda Pathani Bhopal (M.P.) 462022",
  mobile: `+91-6264021848
           +91-7879487462`,
  language: "Hindi, English",
};

//Title
export const title = "NEW TECH ENGINEERING";
